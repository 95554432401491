import React from "react"
import PropTypes from "prop-types"

import '../styles/main.less'

const Layout = ({ children }) => {
  return (
      <div className="page-container">
        <main>{children}</main>
      </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
